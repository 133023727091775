@import url(//fonts.googleapis.com/earlyaccess/notonastaliqurdu.css);
@import url(//fonts.googleapis.com/earlyaccess/notonaskharabic.css);

.eng-font {
  font-family: inherit;
}
.urdu-font {
  font-family: "mher", "Noto Nastaliq Urdu", "Noto Naskh Arabic UI",
    "Noto Sans UI", Tahoma, serif !important;
  line-height: normal !important;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: normal !important;
  }
}
.arabic-font {
  font-family: "Noto Naskh Arabic", "Noto Naskh Arabic UI", "Noto Sans UI",
    Tahoma, serif !important;
}
